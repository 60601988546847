<template>
  <div class="cart" v-if="userInfo != null">
    <van-row type="flex" class="ginfo" :gutter="16">
      <van-col span="6"><img :src="detail.titleimg"/></van-col>
      <van-col span="18">
        <p class="title"><span class="package" v-if="detail.package_key>0">[{{detail.package_name}}]</span>{{detail.title}}</p>
        <p class="price"><span>¥<em>{{detail.now_price}}</em>元</span><s>¥{{detail.old_price}}元</s></p>
        <p class="stock">库存{{detail.stock}}{{detail.dw}}</p>
      </van-col>
      <van-col span="24" class="mt10">
        <van-divider :style="{padding: '0px',height:'2px',margin:'0px'}"/>
      </van-col>
    </van-row>
    
    <van-cell>
      <template #title>
        购买数量<span class="max_pay" v-if="detail.max_paynum>0">(限购{{detail.max_paynum}}{{detail.dw}})</span>
      </template>
      <template #default>
        <van-stepper v-model="json.num" disable-input :max="detail.max_paynum==0?undefined:detail.max_paynum"/>
      </template>
    </van-cell>
    <van-form @submit="onSubmit">
    <van-row type="flex" justify="space-between" class="ship_method">
      <van-col>配送方式</van-col>
      <van-col>
        <van-radio-group v-model="json.ship_method">
          <van-radio :name="1">自提</van-radio>
        </van-radio-group>
      </van-col>
    </van-row>

    <van-cell-group class="mt10">
      <van-field v-model="json.name" label="姓名" placeholder="请输入姓名" input-align="right" :rules="[{ required: true,message: '请输入姓名'}]" :error="false" error-message-align="right"/>
      <van-field v-model="json.mobile" label="手机" placeholder="请输入联系方式" input-align="right" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" :error="false" error-message-align="right"/>
    </van-cell-group>
  
    <van-popup value position="bottom" :overlay="false" :lock-scroll="false" class="cartAction">
      <van-row type="flex" justify="center">
        <van-col span="12" class="total_price">合计：{{json.num * detail.now_price | keepTwoNum}}元</van-col>
        <van-col span="12">
          <van-button type="warning" block class="buybtn" color="#999" disabled v-if="detail.stock<=0">已售罄</van-button>
          <van-button type="warning" block class="buybtn" color="#fc263e" v-else native-type="submit" :loading="loading">立即购买</van-button>
        </van-col>
      </van-row>
    </van-popup>
    </van-form>
    <global :hdid="detail.hdid" v-if="detail.id"/>
  </div>
</template>

<script>
  import global from './components/global'
  import {keepTwoNum} from '@/utils/util'
  import {GetgoodInfo,OrderCreate} from './services/services'
  import {mapGetters} from "vuex";
export default {
  name:'cart',
  components: {global},
  data() {
    return {
      pid:0,
      detail:{},
      json:{
        orderId:undefined,
        num:1,
        ship_method:1,
        name:'',
        mobile:''
      },
      loading:false,
      jsApiParameters:{}
    }
  },
  computed:{
    ...mapGetters(['snapshotuser','userInfo']),
  },
  filters:{
    keepTwoNum
  },
  created(){
    let pid=this.$route.params.pid
    this.pid=pid==undefined?undefined:parseInt(pid)
  },
  mounted() {
    this.findDetail()
  },
  methods: {
    findDetail(){
      GetgoodInfo({id:this.pid}).then(result => {
        if(result.data.code==1){
          this.detail=result.data.data
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    validatorPhone(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    onSubmit(){
      this.loading=true
      OrderCreate({
        ...this.json,
        hdid:this.detail.hdid,
        pid:this.pid
      }).then(result => {
        this.loading=false
        if(result.data.code==1){
          const {orderId,jsApiParameters} = result.data.data
          this.json.orderId=orderId
          if(orderId != undefined){
            if(jsApiParameters!=undefined){
              this.jsApiParameters=jsApiParameters
              this.callpay()
            }else{
              location.href = this.detail.hdInfo.url+'/wx/order/'+this.detail.hdid
            }
          }
        }else{
          this.$toast(result.data.msg)
        }
      })
      
    },
    callpay(){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
          document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
        }
      }else{
        this.jsApiCall();
      }
    },
    jsApiCall(){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          ...this.jsApiParameters
        },(res)=>{
          WeixinJSBridge.log(res.err_msg);
          if(res.err_msg == 'get_brand_wcpay_request:ok'){
            location.href = this.detail.hdInfo.url+'/wx/order/'+this.detail.hdid
          }else if(res.err_msg == 'get_brand_wcpay_request:fail'){
            location.href = this.detail.hdInfo.url+'/wx/order/'+this.detail.hdid
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.cart{
  .ginfo{background:#fff;padding:10px 10px 0px 10px;
    img{width:100%;}
    .title{font-weight:700;
      .package{color:#ee0a24;}
    }
    .price{margin-top:6px;
      span{color:#fc263e;
        em{font-style:normal;font-size:20px;}
      }
      s{margin-left:20px;font-size:12px;color:#999;}
    }
    
    .stock{margin-top:3px;color:#999;}
  }
  .max_pay{color:#ee0a24;}
  .ship_method{padding:10px;background:#fff;margin-top:10px;}
}
  .cartAction{
    .total_price{height:44px;line-height:44px;text-align:center;}
    .buybtn{border-radius:0px;}
  }
</style>
